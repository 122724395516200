<template>
	<div class="">
		<div class="flex justify-center">
			<el-image :src="blog.image" lazy fit="fill"></el-image>
		</div>
		<div class="space-x-4">
			<div class="blog-title">
				<p class="font-semibold text-gray-800 text-lg">
					{{ blog.title }}
				</p>
				<br />
				<small class="text-gray-400"
					>{{ $t("published") }}: {{ blog.date_created }}</small
				>
			</div>
		</div>
		<div class="flex flex-col mt-3">
			<div v-html="cleanMessage(blog.content)"></div>
		</div>
		<br />
		<p class="mt-2 mr-2" v-if="blog.category !== ''">
			{{ $t("category") }}:
			<span class="ml-2" v-html="renderArrays(blog.category)"></span>
		</p>
		<p class="mt-3" v-if="blog.tags.length > 0">
			{{ $t("tags") }}: <span v-html="renderArrays(blog.tags)"></span>
		</p>
	</div>
</template>

<script>
export default {
	name: "BlogPostCard",
	props: {
		blog: {
			type: Object,
			required: true,
		},
	},
	methods: {
		renderArrays(data) {
			const result = [];
			let html = "";
			if (data.length > 0) {
				data.map((v) => result.push(v.name));
				// return result.join(', ')
				result.forEach((element) => {
					html += `<span style='font-size:10px !important' class='px-2 text-gray-700 py-1 border text-xs rounded-full badge badge-white'>${element}</span>&nbsp;`;
				});
				return html;
			}
		},
		cleanMessage(message) {
			return message;
		},
	},
};
</script>

<style scoped></style>
