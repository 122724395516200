<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div
				class="flex flex-col mb-3 md:flex-row justify-between align-items-center"
			>
				<div class="">
					<!--							<span data-cy='article-heading'-->
					<!--								  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{-->
					<!--									GET_SINGLE_BLOG.title-->
					<!--								}}</span>-->
				</div>
				<div class="">
					<router-link
						:to="{
							name: 'bloglist',
							params: {
								company_id: this.$route.params.company_id,
							},
						}"
						class="mr-2 flex justify-between align-items-center space-x-4 no-deco font-semibold text-blue-800 text-lg"
					>
						{{ $t("back") }}
					</router-link>
				</div>
			</div>

			<div class="row" v-if="GET_SINGLE_BLOG.content">
				<div class="col-md-9 col-lg-9 bg-white border rounded-md p-5">
					<blog-post-card
						:blog="GET_SINGLE_BLOG"
						v-if="GET_SINGLE_BLOG.recommended_products.length <= 0"
					/>
					<div class="row" v-else>
						<div class="col-md-7 post-container">
							<blog-post-card :blog="GET_SINGLE_BLOG" />
						</div>
						<div class="col-md-5 post-container">
							<div class="row sm-mb-10">
								<blog-recommended-product
									:key="key"
									:item="item"
									class="col-md-12 mb-3"
									v-for="(
										item, key
									) in GET_SINGLE_BLOG.recommended_products"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-lg-3">
					<div class="bg-white border rounded-md p-1">
						<div class="flex flex-col space-y-5 p-3 rounded-md">
							<span class="font-semibold text-lg text-gray-900">{{
								$t("archives")
							}}</span>
							<div class="flex flex-col">
								<ul class="space-y-2">
									<li
										class="flex align-items-center space-x-4 cursor-pointer"
										v-on:click="
											blogFilter('month', archive.month)
										"
										v-for="(archive, key) in GET_ARCHIVES"
										:key="key"
									>
										<span class="text-gray-900"
											>{{
												$t(
													`${archive.month_str.toLowerCase()}`
												)
											}}
											{{ archive.year }} ({{
												archive.count
											}})</span
										>
									</li>
								</ul>
							</div>
						</div>
						<div class="flex flex-col space-y-5 p-3 rounded-md">
							<span class="font-semibold text-lg text-gray-900">{{
								$t("category")
							}}</span>
							<div class="flex flex-col">
								<ul
									class="space-y-2"
									v-if="GET_BLOG_CATEGORY.data"
								>
									<li
										class="flex align-items-center space-x-4 cursor-pointer"
										v-on:click="
											blogFilter(
												'category',
												category.name
											)
										"
										v-for="category in GET_BLOG_CATEGORY.data"
										:key="category.id"
									>
										<span class="text-gray-900"
											>{{ category.name }} ({{
												category.post_counts
											}})</span
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12"></div>
			</div>
		</div>
		<!-- <Notification /> -->
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import VLazyImage from "v-lazy-image/v2";
import BlogPostCard from "./component/BlogPostCard";
import BlogRecommendedProduct from "../portal/components/categoryTreeComponent/BlogRecommendedProduct";

export default {
	name: "BlogList",
	components: { VLazyImage, BlogPostCard, BlogRecommendedProduct },
	data() {
		return {
			title: "",
			datePublish: "",
			processing: false,
			loading: true,
			archive_loading: true,
			more: false,
			companyId: "",
			searchName: "",
			filteredBlog: [],
		};
	},

	computed: {
		...mapGetters({
			GET_BLOG_CATEGORY: "customer/GET_BLOG_CATEGORY",
			GET_SINGLE_BLOG: "customer/GET_SINGLE_BLOG",
			GET_BLOGS: "customer/GET_BLOG_POST",
			GET_ARCHIVES: "customer/GET_ARCHIVES",
		}),
	},
	mounted() {
		const { company_id, id } = this.$route.params;
		this.companyId = company_id;
		if (company_id && company_id !== "invalid_company") {
			this.getSingleBlog({ company_id, id });
			this.getBlogCategory(company_id);
			this.getBlogList(company_id);
			this.getBlogArchive(company_id);
		} else {
			this.$services.helpers.notification(
				this.$t("no_company_text"),
				"error",
				this
			);
		}
	},
	methods: {
		getBlogCategory(data) {
			this.$store.dispatch("customer/getBlogCategory", { id: data });
		},
		getSingleBlog(data) {
			this.searchName = "";
			this.filteredBlog = [];
			this.loading = true;
			this.$store
				.dispatch("customer/getSingleBlogInformation", {
					company: data.company_id,
					id: data.id,
				})
				.then((_) => {
					this.loading = false;
				})
				.catch((_) => {
					this.loading = false;
				});
		},
		renderArrays(data) {
			const result = [];
			let html = "";
			if (data.length > 0) {
				data.map((v) => result.push(v.name));
				// return result.join(', ')
				result.forEach((element) => {
					html += `<span style='font-size:10px !important' class='px-2 text-gray-700 py-1 border text-xs rounded-full badge badge-white'>${element}</span>&nbsp;`;
				});
				return html;
			}
		},
		getBlogList(id) {
			this.archive_loading = true;
			this.$store
				.dispatch("customer/getBlog", id)
				.then((_) => {
					this.archive_loading = false;
				})
				.catch((_) => {
					this.archive_loading = false;
				});
		},
		filterBlog() {
			let pageUrl = `?company_id=${this.company_id}&limit=${this.limit}`;
			if (this.searchName === "") {
				pageUrl = pageUrl + `&q=${this.searchName}`;
			}
			this.$store
				.dispatch("customer/getBlogs", { url: pageUrl })
				.then((_) => {
					this.filteredBlog = this.GET_BLOGS.data;
				})
				.catch((_) => {});
		},
		toggleMore(status) {
			this.more = status;
		},
		blogFilter(status, value) {
			if (status === "category") {
				this.$router.replace({
					name: "bloglist",
					query: { category: value },
					params: { company_id: this.companyId },
				});
			} else if (status === "month") {
				this.$router.replace({
					name: "bloglist",
					query: { month: value },
					params: { company_id: this.companyId },
				});
			}
		},
		getBlogArchive(id) {
			this.$store.dispatch("customer/getArchives", {
				url: `?company_id=${id}`,
			});
		},
		cleanMessage(message) {
			return message;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #f167ca !important;
	border-color: #f167ca !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
		0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
		0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
		0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all 0.2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2cdca7 !important;
	border-color: #2cdca7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.width-24 {
	width: 24%;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table th {
	// padding: 0.1rem 0.55rem !important;
	color: #000000 !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-select {
	border: 1px solid #dfe0eb !important;
	height: unset !important;
	padding: 0.175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table-striped tbody:nth-of-type(2n + 1) {
	background-color: rgba(0, 0, 0, 0.03);
}

.c-border {
	border-width: 1px !important;
}

.transform-178 {
	transform: rotate(178deg);
}

.text_sm_10 {
	font-size: 10px !important;
}

.br-50 {
	border-radius: 50% !important;
}

.cursor {
	cursor: pointer;
}

.blog-title {
	margin: auto;
	text-align: center;
	margin-top: 44px;
	width: 70%;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 17px;
	letter-spacing: 0.3px;
}

.el-image {
	height: 400px;
}

.margin-right-17 {
	margin-right: 17px !important;
}

@media screen and (max-width: 600px) {
	.sm-mb-10 {
		margin-top: 25px !important;
	}
}

@media (max-width: 1250px) {
	.post-container {
		max-width: 100% !important;
		flex: 0 0 100% !important;
	}
	.sm-mb-10 {
		margin-top: 25px !important;
	}
}
</style>
